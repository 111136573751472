@charset "utf-8";

///////////////
// Custom:
// disable animations
$intro-transition  : none;
$global-transition : none;

$primary-color: #1f6aed;
$link-color: #1f6aed;
///////////////

@import "minimal-mistakes/skins/contrast"; // skin
@import "minimal-mistakes"; // main partials

///////////////
//// Custom:
a.invisilink {
  text-decoration: none;
  color: $text-color !important;
}

// for portfolio pages, align title with top of image
.page__content h2.archive__item-title {
  margin-top: 0;
}
///////////////
